import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import {
  Table, TableBody, TableCell, TableRow,
  Typography
} from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { sheens } from '../../../data/paint-modals';
import sharedStyles from '../paint-modal-content.module.scss';
import styles from './Sheen.module.scss';

const cx = classNames.bind({ ...styles, ...sharedStyles });

const FINISH_PERFORMANCE_LABEL = 'FINISH PERFORMANCE';
const BEST_USES_LABEL = 'BEST USES';
const BEST_SUITED_LOCATIONS_LABEL = 'BEST SUITED LOCATIONS';

export const Sheen = ({ colorHex }) => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('brands.ready'); }, []);

  return (
    <div
      className={cx('sheen-modal', 'paint-modal-content', 'sui-h-full', 'sui-px-2')}
      data-component="PaintModalContentSheen"
    >
      <div className="sui-py-5">
        <Typography variant="h1" component="h2">
          Sheen Options
        </Typography>
      </div>
      <p className="sui-w-full sui-pb-3">
        Take the guesswork out of finding the perfect paint sheen for your next paint project.
      </p>
      <div className="sui-h-full sui-max-w-full sui-overflow-auto">
        <Table unlined>
          <TableBody>
            <TableRow>
              <TableCell component="th" sticky="horizontal" />
              {sheens.map((sheen) => {
                const { image, name } = sheen;
                return (
                  <TableCell align="center" component="th">
                    <img
                      className={cx('sheen__image')}
                      src={image}
                      alt={name}
                      style={{ backgroundColor: colorHex }}
                      key={name}
                      width={147}
                      height={84}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal" />
              {sheens.map((sheen) => {
                const { name } = sheen;
                return (
                  <TableCell key={name} component="th">{name}</TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal">{FINISH_PERFORMANCE_LABEL}</TableCell>
              {sheens.map((sheen) => {
                const { name, finishPerformance } = sheen;
                return (
                  <TableCell key={name}>{finishPerformance}</TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal">{BEST_USES_LABEL}</TableCell>
              {sheens.map((sheen) => {
                const { name, bestUses } = sheen;
                return (
                  <TableCell key={name}>{ bestUses }</TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell component="th" sticky="horizontal">{BEST_SUITED_LOCATIONS_LABEL}</TableCell>
              {sheens.map((sheen) => {
                return (
                  <TableCell key={sheen.name}>
                    {
                      sheen.bestSuitedLocations.map((location) => {
                        return (
                          <Typography
                            variant="body-base"
                            height="tight"
                            weight="regular"
                            key={location}
                          >{location}
                          </Typography>
                        );
                      })
                    }
                  </TableCell>
                );
              })}
            </TableRow>
            {!isMobile && (
              <TableRow>
                <TableCell component="th" sticky="horizontal" />
                <TableCell colSpan="5">
                  <div className={cx('arrow')}> </div>
                  <div className="sui-flex sui-justify-between">
                    <div className={cx('stain-resistance')}>LESS STAIN RESISTANCE</div>
                    <div className={cx('stain-resistance')}>SUPERIOR STAIN RESISTANCE</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

Sheen.propTypes = {
  colorHex: string.isRequired,
};

Sheen.displayName = 'PaintModalContent.Sheen';
