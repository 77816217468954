import React, { useEffect } from 'react';
import classNames from 'classnames/bind';

import { Typography } from '@one-thd/sui-atomic-components';

import { paintTypes } from '../../../data/paint-modals';
import styles from './PaintType.module.scss';

const cx = classNames.bind(styles);

export const PaintType = () => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('paintType.ready'); }, []);

  const { description, types } = paintTypes;

  return (
    <div className={cx('type-modal', 'sui-px-2')} data-component="PaintModalContentPaintType">
      <div className="sui-py-5">
        <Typography variant="h1" component="h2">
          Paint Types
        </Typography>
      </div>
      <div className={cx('type-modal__description')}>{description}</div>
      <div className={cx('type-modal__tiles')}>
        {types?.map((type) => {
          const { imageUrl, options, paintType } = type;
          return (
            <div className={cx('type-modal__tile')} key={paintType}>
              <img className={cx('tile__image')} src={imageUrl} alt={paintType} height="auto" width="auto" />
              <div className={cx('options')}>
                <div className={cx('options-title')}>{paintType}</div>
                <ul className={cx('options-list')}>
                  {options.map((option, index) => {
                    return (
                      <li className={cx('option')} key={`option-${index + 1}`}>{option}</li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PaintType.displayName = 'PaintType';
