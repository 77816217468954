import React from 'react';
import classNames from 'classnames/bind';
import { Footer } from '../Footer/Footer';
import { calculateSize } from '../../../../data/paint-modals';
import styles from './EasyEstimator.module.scss';

const cx = classNames.bind(styles);

export const EasyEstimator = () => {
  const { estimatesPerRoom } = calculateSize;
  return (
    <div className={cx('easy-estimator')}>
      <div className={cx('estimation-info')}>
        {estimatesPerRoom.map((room) => {
          const { name, image, roomSize, amountNeeded } = room;
          return (
            <div className={cx('estimate-section')} key={name}>
              <div className={cx('room-image')}>
                <img src={image} alt={name} height="auto" width="auto" />
              </div>
              <div className={cx('room-details')}>
                <div className={cx('room-info')}>
                  Average&nbsp;
                  <span>{name}</span>
                </div>
                <div className={cx('room-info-items')}>
                  <span>{roomSize}&nbsp;</span>
                  square feet
                </div>
                <div className={cx('amount')}>{amountNeeded}</div>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

EasyEstimator.displayName = 'EasyEstimator';
