import React from 'react';
import { string } from 'prop-types';
import { InformationModal } from '@thd-olt-component-react/information-modal';
import { PaintModalContent } from '@thd-olt-component-react/paint-modal-content';
import { publish } from '../../publisher';
import './attribute-label.scss';

export const AttributeLabelModal = (props) => {
  const { attributeName, hexColor, brandName, type } = props;

  const onModalClick = () => {
    const labels = {
      'Color/Finish': 'Paint Types',
      'Paint Type': 'Paint Types',
      Sheen: 'Sheen Options',
      'Brand Name': 'Brand Options'
    };
    const data = {
      target: 'Help me choose',
      component: labels[attributeName],
      section: 'super-sku'
    };

    publish('click-help-me-choose', data);
  };

  const paintType = attributeName === 'Color/Finish' || attributeName === 'Paint Type';
  const sheen = attributeName === 'Sheen';
  const brand = attributeName === 'Brand Name';

  const overlayProps = {
    fit: paintType || brand,
    medium: sheen
  };

  return (
    <>
      <InformationModal
        linkTitle="Help Me Choose"
        onClick={onModalClick}
        overlayProps={overlayProps}
      >
        {paintType && <PaintModalContent.PaintType />}
        {brand && <PaintModalContent.Brand brandName={brandName} type={type} />}
        {sheen && <PaintModalContent.Sheen colorHex={`#${hexColor}`} />}
      </InformationModal>
    </>
  );
};

AttributeLabelModal.displayName = 'AttributeLabelModal';

AttributeLabelModal.propTypes = {
  attributeName: string.isRequired,
  brandName: string,
  hexColor: string,
  type: string
};

AttributeLabelModal.defaultProps = {
  brandName: '',
  hexColor: '',
  type: ''
};
