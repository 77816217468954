import { copy } from '@thd-olt-functional/utils';
import { COMBINATION_DELIMITER, KEY_VALUE_DELIMITER } from '../statics';
import {
  findClosestSku,
  getAttributeCombination,
  getEnabledAndAvailableAttributeValues,
  getFormattedChildItemsLookup,
  getMatchedAttributeCombinationDetails,
  getProcessedInventoryData,
  getProcessedPricingData,
  getProductInformation,
  getSelectedAttributes,
  isOverflowSection,
  reorderAttributesForPatio,
  shouldDisplayInventory,
  shouldDisplayTooltips,
  shouldDisplayAlerts
} from '../super-sku-util';

export const initializeState = (state, action) => {
  const {
    response: data = {},
    itemId,
    disableMediaAndAvailability,
    collectionId,
    parentId,
    itemSizeAndFitDetail,
    isDiscontinued,
    isOutOfStock,
    info,
    isUnavailableInStore,
    specificationGroup,
    paintDetails,
    brandName
  } = action;

  const initialState = {
    itemId,
    superDuperSku: copy(data?.superDuperSku || {}).data,
    attributes: [],
    jumpedState: false,
    impossibleState: false,
    unavailableState: false,
    showAlerts: false,
    isUnavailableInStore,
    isTooltipsEnabled: false,
    childItemsLookup: [],
    selectedAttributes: [],
    overflowValues: [],
    canonicalUrl: null,
    disableMediaAndAvailability,
    collectionId,
    parentId,
    superSkuSizeAndFitDetail: copy(data?.sizeAndFitDetail || {}, {}).data,
    itemSizeAndFitDetail,
    isDiscontinued,
    isOutOfStock,
    info,
    previousParentId: null,
    specificationGroup,
    paintDetails: copy(paintDetails).data,
    brandName,
    isSskuClicked: false
  };

  let { attributes, childItemsLookup } = data;

  const isPatio = attributes[1] && attributes[1].attributeName === 'Fabric Type';

  const attributesReordered = isPatio ? reorderAttributesForPatio(attributes) : attributes;
  initialState.childItemsLookup = getFormattedChildItemsLookup(attributesReordered, childItemsLookup);
  initialState.attributes = attributesReordered.map((attribute) => {
    return {
      ...attribute,
      isAvailable: true,
      isOverflowSection: false
    };
  });

  const selectedProduct = data.childItemsLookup?.find((item) => {
    return item.itemId === itemId;
  });

  if (selectedProduct) {
    let currentlySelected = selectedProduct.attributeCombination?.split(COMBINATION_DELIMITER);
    currentlySelected = isPatio
      ? reorderAttributesForPatio(currentlySelected)
      : currentlySelected;
    initialState.selectedAttributes = currentlySelected?.map((attributeValue, index) => {
      return {
        attributeLabel: attributesReordered?.[index]?.attributeName,
        attributeValue: (attributeValue.indexOf(KEY_VALUE_DELIMITER) >= 0
          ? attributeValue.split(KEY_VALUE_DELIMITER)[1]
          : attributeValue),
        isSwatch: attributesReordered?.[index]?.isSwatch
      };
    });
    initialState.itemId = selectedProduct.itemId;
    initialState.canonicalUrl = selectedProduct.canonicalUrl;
  } else {
    initialState.impossibleState = true;
  }

  const dominantIndex = initialState.attributes.findIndex((definingAttribute) => {
    return definingAttribute.attributeValues.length > 1;
  });

  if (dominantIndex !== -1) {
    initialState.attributes[dominantIndex].isDominantAttribute = true;
  }

  initialState.enabledAndAvailableAttributeValues = getEnabledAndAvailableAttributeValues(initialState);

  if (initialState.superDuperSku.attributes?.length) {
    initialState.superDuperSku.attributes = data.superDuperSku.attributes.map((attribute) => {
      return {
        isAvailable: true,
        ...attribute
      };
    });
  }

  initialState.showAlerts = shouldDisplayAlerts(initialState);

  return initialState;
};

export const createErrorState = () => {
  return {
    errorState: true
  };
};

export const createLoadingState = (state) => {
  return {
    ...state,
    loadingState: true
  };
};

export const createNoDataState = () => {
  return {
    noDataState: true
  };
};

export const resetOverflowValues = (state, action) => {
  return {
    ...state,
    attributes: state.attributes.map((attribute) => {
      if (attribute.attributeName === action.attributeName) {
        return {
          ...attribute,
          isOverflowSection: false,
          scrollHeight: action.scrollHeight
        };
      }
      return attribute;
    })
  };
};

export const resetSdskuOverflowValues = (state, action) => {
  return {
    ...state,
    superDuperSku: {
      ...state.superDuperSku,
      attributes: state.superDuperSku.attributes.map((attribute) => {
        if (attribute.attributeName === action.attributeName) {
          return {
            ...attribute,
            isOverflowSection: false,
            scrollHeight: action.scrollHeight
          };
        }
        return attribute;
      })
    }
  };
};

export const updateOverflowValues = (state, action) => {
  const { attributeRef, attributeName } = action;

  let updatedState = {
    ...state
  };

  if (attributeRef?.current) {
    const overflowValue = isOverflowSection(attributeRef?.current);

    updatedState.attributes = state.attributes.map((attribute) => {
      if (attribute.attributeName === attributeName) {
        return {
          ...attribute,
          isOverflowSection: overflowValue
        };
      }
      return attribute;
    });
  }

  return updatedState;
};

export const updateSdskuOverflowValues = (state, action) => {
  const { attributeRef, attributeName } = action;

  if (attributeRef?.current) {
    const overflowValue = isOverflowSection(attributeRef?.current);

    return {
      ...state,
      superDuperSku: {
        ...state.superDuperSku,
        attributes: state.superDuperSku.attributes.map((attribute) => {
          if (attribute.attributeName === attributeName) {
            return {
              ...attribute,
              isOverflowSection: overflowValue
            };
          }
          return attribute;
        })
      }
    };
  }

  return state;
};

export const selectNewAttribute = (state, action) => {
  const {
    attributeLabel,
    attributeValue
  } = action;

  let updatedState = {
    ...state,
    unavailableState: false,
    isDiscontinued: false,
    isOutOfStock: false,
    isSskuClicked: true
  };

  const clickedTileInformation = {
    attributeLabel,
    attributeValue
  };

  updatedState.selectedAttributes = getAttributeCombination(state.selectedAttributes, clickedTileInformation);

  const newlySelectedAttributes = getSelectedAttributes(updatedState, clickedTileInformation);

  if (newlySelectedAttributes?.selectedAttributes.length > 0) {
    updatedState = {
      ...updatedState,
      ...newlySelectedAttributes,
      impossibleState: false
    };
  } else {
    updatedState.impossibleState = true;
  }

  updatedState = {
    ...updatedState
  };

  updatedState.previousParentId = state.parentId;

  return {
    ...updatedState
  };
};

export const updateSelectedProductInfo = (state, action) => {

  let updatedState = {
    ...state,
    ...getProductInformation(state),
    isSskuClicked: false
  };
  updatedState.enabledAndAvailableAttributeValues = getEnabledAndAvailableAttributeValues(updatedState);
  return updatedState;
};

export const hoverAttribute = (state, action) => {
  const {
    attributeLabel,
    attributeValue
  } = action;

  let hoveredState = {
    ...state
  };

  const hoveredTileInformation = {
    attributeLabel,
    attributeValue
  };

  hoveredState.selectedAttributes = getAttributeCombination(state.selectedAttributes, hoveredTileInformation);

  const hoveredCombinationDetails = getMatchedAttributeCombinationDetails(hoveredState);
  if (hoveredCombinationDetails) {
    hoveredCombinationDetails.isHovered = true;
  }

  return {
    ...state,
    hoveredCombinationDetails
  };
};

export const resetHover = (state) => {
  return {
    ...state,
    hoveredCombinationDetails: {
      isHovered: false
    }
  };
};

export const updateChildItemsLookupWithMediaPriceAndInventory = (state, action) => {
  const { productDetailsList = [] } = action?.response;
  const { channel } = action;

  let updatedState = {
    ...state
  };

  updatedState.childItemsLookup = state?.childItemsLookup?.map((childItem) => {
    const matchedItems = productDetailsList.find((item) => {
      return item?.itemId === childItem?.itemId;
    });

    let updatedChildItem = {
      ...childItem
    };

    if (matchedItems) {
      const { imageLocation, pricing, onlineInventory, storeInventory } = matchedItems;
      updatedChildItem.image = imageLocation;
      updatedChildItem.pricing = getProcessedPricingData(pricing);
      if (shouldDisplayInventory(state)) {
        const { isItemBackOrdered } = childItem;

        updatedChildItem.inventory = getProcessedInventoryData(onlineInventory,
          storeInventory, isItemBackOrdered);

        if (state.itemId === updatedChildItem.itemId) {
          updatedState.isAvailable = updatedChildItem.inventory.isAvailable;
          updatedState.unavailableState = !updatedState.isAvailable;
        }
      }

      let updatedChildCombinationDetails = childItem.attributeCombination.split(COMBINATION_DELIMITER);
      if (childItem['Fabric Type']) {
        updatedChildCombinationDetails = reorderAttributesForPatio(updatedChildCombinationDetails);
      }
      updatedChildItem.combinationDetails = updatedChildCombinationDetails
        .map((attributeValue, attributeIndex) => {
          return {
            attributeLabel: state.attributes[attributeIndex]?.attributeName,
            attributeValue,
            shouldDisplayAttributeLabel: (!state.attributes[attributeIndex]?.swatch && attributeValue.length < 5)
          };
        });
    }

    return {
      ...updatedChildItem
    };
  });

  if (shouldDisplayInventory(state)) {
    updatedState.enabledAndAvailableAttributeValues = getEnabledAndAvailableAttributeValues(updatedState);
    if (updatedState.enabledAndAvailableAttributeValues.available) {
      updatedState.showAlerts = shouldDisplayAlerts(updatedState);
    }
  }

  updatedState.isTooltipsEnabled = shouldDisplayTooltips(state, channel) && productDetailsList.length !== 0;
  return {
    ...updatedState
  };
};

export const selectNewSdskuAttribute = (state, action) => {
  let updatedState = {
    ...state
  };

  const { metadata } = action;
  const clickedTile = {};

  let { attributes, childItemsLookup } = metadata;
  attributes = attributes[1]?.attributeName === 'Fabric Type'
    ? reorderAttributesForPatio(attributes)
    : attributes;

  const formattedChildItemsLookup = getFormattedChildItemsLookup(attributes, childItemsLookup);
  const canonicalURL = findClosestSku(state, clickedTile, formattedChildItemsLookup)?.canonicalUrl;

  updatedState.canonicalUrl = canonicalURL;
  updatedState.itemId = canonicalURL.split('/')?.pop();

  return updatedState;
};

export const updateAlertValues = (state, action) => {
  const {
    isDiscontinued,
    isOutOfStock,
    isUnavailableInStore,
    disableMediaAndAvailability
  } = action;

  const { itemId, childItemsLookup } = state;
  const { inventory } = childItemsLookup?.find((item) => item?.itemId === itemId) || {};
  const isUnavailable = !disableMediaAndAvailability && (isUnavailableInStore || (inventory && !inventory.isAvailable));

  return {
    ...state,
    isDiscontinued,
    isOutOfStock,
    isUnavailableInStore,
    unavailableState: isUnavailable
  };
};
