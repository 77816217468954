import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { oneOf, string as stringType } from 'prop-types';
import {
  params, string, shape, useDataModel
} from '@thd-nucleus/data-sources';
import {
  Table, TableBody, TableCell, TableRow, Typography, Skeleton, SkeletonContent, SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { brands, brandNames } from '../../../data/paint-modals';
import sharedStyles from '../paint-modal-content.module.scss';
import styles from './Brand.module.scss';
import { getBrandKey, validateBrandData } from './brand-utils';

const cx = classNames.bind({ ...styles, ...sharedStyles });

const Brand = ({ brandName, type }) => {
  const brandKey = getBrandKey(brandName);

  if (brandKey === null) {
    return (
      <div className="sui-w-full sui-h-72 sui-flex sui-flex-col sui-justify-center sui-text-center">
        <h2>Sorry, there is no content available for this brand.</h2>
      </div>
    );
  }

  const brandData = brands[brandKey]?.[type];
  const disclaimers = brands[brandKey]?.disclaimers;

  const { error, success } = validateBrandData(brandData);

  if (!success) {
    return (
      <div data-testId="brand-modal-error">
        <h1>CONTENT ERROR:</h1>
        <h4>{error}</h4>
      </div>
    );
  }

  return (
    <div
      className={cx('brand-modal', 'paint-modal-content', 'sui-px-2')}
      data-component="PaintModalContentBrand"
      data-testId="modal-content"
    >
      <div className="sui-py-5">
        <Typography variant="h1" component="h2">
          {`Brand Options (${type})`}
        </Typography>
      </div>
      <p className="sui-w-full sui-pb-3">
        Our top paint brands side by side. Compare performance,
        durability, value, and more.
      </p>
      <div className="sui-max-h-full sui-max-w-full sui-overflow-auto">
        <div className="sui-max-h-full sui-max-w-full sui-overflow-auto">
          <Table unlined>
            <TableBody>
              <TableRow>
                <TableCell component="th" sticky="horizontal" />
                {brandData.map((brand) => {
                  const { image = null, name, showName = false } = brand;

                  return (
                    <TableCell>
                      <div className="sui-flex sui-flex-col sui-justify-center sui-align-middle sui-w-full sui-h-24">
                        {image && (
                          <img
                            className="sui-mx-auto"
                            src={image}
                            alt={name}
                            key={name}
                            width={96}
                            height={96}
                          />
                        )}
                        {showName && (
                          <div className="sui-mx-auto sui-mt-auto">
                            <Typography>
                              {name}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
              {Object.keys(brandData[0])
                .filter((key) => key !== 'name' && key !== 'image' && key !== 'showName')
                .map((key) => {
                  return (
                    <TableRow>
                      <TableCell component="th" sticky="horizontal">{key}</TableCell>
                      {brandData.map((brand) => {
                        return (<TableCell key={`${brand.name}-${key}`} align="center">{brand[key]}</TableCell>);
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <div className="sui-mt-2 sui-flex sui-flex-col sui-gap-1 sui-text-xs">
          {disclaimers.map((disclaimer, index) => {
            return <div>{`${Array(index + 1).fill('*').join('')} ${disclaimer}`}</div>;
          })}
        </div>
      </div>
    </div>
  );
};

Brand.displayName = 'PaintModalContent.Brand';

Brand.propTypes = {
  brandName: oneOf(Object.entries(brandNames).reduce((curr, accum) => accum.concat(curr[1], []))).isRequired,
  type: oneOf((['Interior Paint', 'Exterior Paint']))
};

Brand.defaultProps = {
  type: 'Interior Paint',
};

export { Brand };
