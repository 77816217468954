import React, { useState, useEffect, Suspense } from 'react';
import {
  bool,
  string,
  shape,
  node,
  func,
} from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import { Info } from '@one-thd/sui-icons';

const Overlay = React.lazy(() => import(/* webpackChunkName: "overlay" */ '@thd-olt-component-react/overlay')
  .then((module) => ({ default: module.Overlay })));

const doNothingFunction = () => { };

const InformationModal = ({
  linkTitle,
  children,
  onClick,
  onClose,
  overlayProps,
  showInfoIcon
}) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('information-modal.ready'); }, []);

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
    onClick();
  };

  const propsForOverlay = {
    ...InformationModal.defaultProps.overlayProps,
    ...overlayProps,
  };

  const handleOverlayClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div className="sui-ml-4">
      <Button
        variant="text"
        className="sui-h-6 sui-flex sui-flex-row sui-gap-1"
        onClick={handleButtonClick}
      >
        <span
          data-testid="size-guide-link"
          className="sui-text-sm sui-text-primary sui-underline"
        >{linkTitle}
        </span>
        {showInfoIcon && (
          <span className="sui-mt-2px">
            <Info size="small" color="primary" />
          </span>
        )}
      </Button>
      {open && (
        <Suspense fallback={<div>Loading...</div>}>
          <Overlay
            {...propsForOverlay} // eslint-disable-line  react/jsx-props-no-spreading
            onClose={handleOverlayClose}
            open
          >
            {children}
          </Overlay>
        </Suspense>
      )}
    </div>
  );
};

InformationModal.propTypes = {
  children: node.isRequired,
  linkTitle: string.isRequired,
  onClick: func,
  onClose: func,
  overlayProps: shape({
    closeButton: bool,
    onClose: func,
    positionedCloseButton: bool
  }),
  showInfoIcon: bool
};

InformationModal.defaultProps = {
  onClick: doNothingFunction,
  onClose: doNothingFunction,
  overlayProps: {
    closeButton: true,
    positionedCloseButton: true
  },
  showInfoIcon: false,
};

InformationModal.displayName = 'InformationModal';

export { InformationModal };
